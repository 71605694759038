import React from "react";
import { Container, Grid } from "@mui/material";
import {
	GlobalFacts,
	GlobalPopularDisciplines,
	GlobalYoYComparison,
	GlobalWeeklyChart,
} from "../components";

const GlobalPage = ({
	data,
	totals,
	popular,
	lastYear,
	athleteCount,
	colors,
	isDarkTheme,
	chartColor,
}) => {
	return (
		<Container sx={{ my: 5 }} maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<GlobalFacts dimensions={data.dimensions} totals={totals} />
				</Grid>
				<Grid item md={5} xs={12}>
					<Grid container spacing={2} direction="column">
						<Grid item>
							<GlobalPopularDisciplines
								dimensions={data.dimensions}
								popular={popular}
							/>
						</Grid>
						<Grid item>
							<GlobalYoYComparison
								dimensions={data.dimensions}
								totals={totals}
								lastYear={lastYear}
								athleteCount={athleteCount}
								isMetric={data.athlete.metric}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={7} xs={12}>
					<GlobalWeeklyChart
						dimensions={data.dimensions}
						colors={colors}
						foreColor={isDarkTheme ? chartColor.light : chartColor.dark}
						isMetric={data.athlete.metric}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default GlobalPage;
