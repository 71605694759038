import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Typography, Chip } from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import FilterHdrOutlinedIcon from '@mui/icons-material/FilterHdrOutlined';
import RouteIcon from '@mui/icons-material/Route';
import FormatAmount from './FormatAmount.tsx';

ChartJS.register(ArcElement, Tooltip);

class Donut extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hover: false };
        this.configData = this.configData.bind(this);
        this.configOptions = this.configOptions.bind(this);
        this.chartRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selected > -1) {
            const chart = this.chartRef.current;
            chart.tooltip.setActiveElements([{datasetIndex: 0, index: this.props.selected}]);
        } else {
            const chart = this.chartRef.current;
            if (chart && chart.tooltip) {
                chart.tooltip.setActiveElements([]);
            }
        }
        if (prevProps.selected !== this.props.selected) {
            if (prevState && prevState.hover) {
                this.setState({ hover: false });
            }
        }
    }

    configData() {
        let { colors, year, backColor } = this.props;
        delete year.none; 
        let data = Object.values(year);
        let labels = Object.keys(year);
        return {
            labels: labels,
            datasets: [{
                data: data,
                backgroundColor: colors,
                borderColor: new Array(colors.length).fill(backColor),
                borderWidth: 1,
                borderAlign: 'inner'
            }]
        };
    }

    configOptions() {
        let { dimension, isMetric } = this.props;
        return {
            rotation: -153, 
            circumference: 306,
            cutout: '75%',
            legend: {
                display: false
            },
            title: {
                display: false
            },
            plugins: {
                tooltip: {
                    filter: (tooltipItem) => {
                        //No tooltip on any series if value is 0
                        return (tooltipItem.dataset.data[tooltipItem.dataIndex] !== 0);
                    },
                    callbacks: {
                        //Make sure tooltips list the activity type and formatted amount
                        label: (tooltipItem) => {
                            return FormatAmount({
                                value: tooltipItem.dataset.data[tooltipItem.dataIndex],
                                dimension: dimension,
                                isMetric: isMetric,
                                decimals: 2
                            });
                        }
                    }
                },
                legend: {
					display: false
                }
            },
            animation: {
                animateScale: true,
                animateRotate: true
            },
            maintainAspectRatio: false,
            onHover: () => {
                if (!this.state.hover) {
                    this.setState({ hover: true });
                    this.props.resetTooltip();
                }
            }
        };
    }

    render() {
        const { totalYear, ranking, dimension, lastYTD, isMetric } = this.props;
        let challengeIcon;
        switch (dimension.name) {
            case 'Distance': 
                challengeIcon = <RouteIcon fontSize="small"/>;
                break;
            case 'Duration':
                challengeIcon = <TimerOutlinedIcon fontSize="small"/>;
                break;
            default:
                challengeIcon = <FilterHdrOutlinedIcon fontSize="small"/>;
        };
        const data = this.configData();
        let thisYear = FormatAmount({
            value: Math.round(totalYear),
            dimension: dimension,
            isMetric: isMetric,
            decimals: 0
        });
        let lastYear = FormatAmount({
            value: Math.round(lastYTD),
            dimension: dimension,
            isMetric: isMetric,
            decimals: 0
        });
        return (
            <div className='background'>
                <Doughnut
                    data={data}
                    options={this.configOptions()}
                    ref={this.chartRef}
                />
                <div className="donut-data">
                    <div className="text-center">
                        <Typography sx={{ fontSize: 20, textTransform: 'uppercase' }}>
                            {thisYear}
                        </Typography>
                        <Chip size="small" label={"Ranked #"+ranking} color="info" sx={{fontWeight:"bold"}} />
                        <Typography sx={{ fontSize: 14 }}>
                            Compare to {lastYear} last year to date
                        </Typography>
                    </div>
                </div>
                <div className="donut-label-legend">
                    <div className="donut-label">
                        {challengeIcon}
                        <br/>{dimension.name}
                    </div>
                </div>
            </div>
        )
    }
}

export default Donut;