import React from 'react';
import MenuBar from './MenuBar';
import NotificationAchievement from './NotificationAchievement';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const Header = ({
    athleteImageSrc,
    athleteFirstName,
    athleteLastName,
    athleteId,
    isAdmin,
    athleteMetric,
    toggleTheme,
    isDarkTheme,
    athleteOffice,
    athleteTitle,
    notifications,
    notificationCompleted,
    tabSelected,
    highlightedCategory,
    getHeroImage
}) => {
    return (
        <>
            <MenuBar
                athleteImageSrc={athleteImageSrc}
                athleteFirstName={athleteFirstName}
                athleteLastName={athleteLastName}
                athleteId={athleteId}
                isAdmin={isAdmin}
                athleteMetric={athleteMetric}
                toggleTheme={toggleTheme}
                isDarkTheme={isDarkTheme}
                athleteOffice={athleteOffice}
                athleteTitle={athleteTitle}
            />
            <NotificationAchievement
                athleteId={athleteId}
                notifications={notifications}
                notificationCompleted={notificationCompleted}
            />
            <Box
                id="hero-homepage"
                className={tabSelected ? "collapse" : ""}
                sx={getHeroImage(highlightedCategory)}
            >
                <Container
                    sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                    maxWidth="lg"
                    className={tabSelected ? "fade-out" : "fade-in"}
                >
                    <Typography component="h2" variant="heroTitle" color="#18181b">
                        Celebrating {highlightedCategory}
                    </Typography>
                    <Typography component="h3" variant="heroSubtitle">
                        Welcome back, {athleteFirstName}
                    </Typography>
                </Container>
            </Box>
        </>
    );
};

export default Header;