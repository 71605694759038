import React from "react";
import FormatAmount from "./FormatAmount.tsx";
import {
    Grid,
    Card,
    CardHeader,
    CardContent,
    Typography,
    Chip,
    Divider,
    Stack,
    Avatar,
    IconButton,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

const AthleteProfileCard = ({ athlete, dimensions, close, isMetric }) => {
    return (
        <Card
            color="secondary"
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardHeader
                avatar={
                    <Avatar alt={athlete.name} src={athlete.pictureURL} />
                }
                action={
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                }
                title={athlete.name}
                subheader={athlete.title ? athlete.title : 'No Deloitte profile'}
            />
            <CardContent sx={{ textAlign: "center" }}>
                <Stack direction="row" spacing={1} sx={{ minWidth: 0 }}>
                    <Chip size='small' label={athlete.office ? athlete.office : 'No office'} sx={{ mr: 0.5, overflow: 'hidden', textOverflow: 'ellipsis' }} />
                    <Chip size='small' label={athlete.normalizedTitle ? athlete.normalizedTitle : 'No title'} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} />
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Grid container sx={{ mb: 2 }}>
                    {dimensions.map(dim =>
                        <Grid key={dim.id} item xs={4}>
                            <Typography
                                variant="overline"
                                sx={{ display: "block", mb: 0.5 }}
                            >
                                {dim.name}
                            </Typography>
                            <Typography variant="body2" fontSize='0.75rem' fontWeight='bold'>
                                <FormatAmount
                                    value={athlete.totalByDimension.find(total => total.dimensionId === dim.id).total}
                                    dimension={dim}
                                    isMetric={isMetric}
                                    decimals={2}
                                />
                            </Typography>
                            <Typography variant="body2" fontSize='0.75rem'>
                                (Ranked #{athlete.athleteRanking.find(rank => rank.dimensionId === dim.id).position})
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <Grid container sx={{ mb: 2 }}>
                    <Grid item xs={4}>
                        <Typography
                            variant="overline"
                            sx={{ display: "block" }}
                        >
                            Activities
                        </Typography>
                        <Typography variant="body2" fontSize='0.75rem' fontWeight='bold'>
                            {athlete.totalActivities}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="overline"
                            sx={{ display: "block" }}
                        >
                            Team
                        </Typography>
                        <Typography variant="body2" fontSize='0.75rem' fontWeight='bold'>
                            {athlete.teamName}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography
                            variant="overline"
                            sx={{ display: "block" }}
                        >
                            Achievements
                        </Typography>
                        <Typography variant="body2" fontSize='0.75rem' fontWeight='bold'>
                            {athlete.pastAccomplishments}
                        </Typography>
                        <Typography variant="body2" fontSize='0.75rem'>
                            (Ranked #{athlete.athleteRanking.find(rank => rank.dimensionId === 0).position})
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <Grid container sx={{ mb: -2 }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="overline"
                            sx={{ display: "block", pb: '12px' }}
                        >
                            Active Challenges
                        </Typography>
                        <TableContainer>
                            <Table size='small'>
                                <TableBody>
                                    {athlete.activeChallenges.map((challenge, i) =>
                                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align='left' sx={{ borderBottom: 'none', whiteSpace: 'nowrap', maxWidth: 110 }}>
                                                <Typography variant="body2" fontSize='0.75rem' sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} >
                                                    {challenge.description}
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                <span style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'right' }}>
                                                    <Typography variant="body2" fontSize='0.75rem'>
                                                        <FormatAmount
                                                            value={challenge.amount}
                                                            dimension={dimensions.find(dim => dim.id === challenge.dimensionId)}
                                                            isMetric={isMetric}
                                                            decimals={2}
                                                        />
                                                    </Typography>
                                                    {challenge.achieved && <span>&nbsp;</span>}
                                                    {challenge.achieved && <EmojiEventsIcon sx={{ color: "#F2B256!important" }} fontSize='6px' />}
                                                </span>
                                            </TableCell>
                                            <TableCell align='right' sx={{ borderBottom: 'none' }}>
                                                <Typography variant="body2" fontSize='0.75rem' sx={{ borderBottom: 'none' }}>
                                                    Rank #{challenge.rank}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AthleteProfileCard;