import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    Avatar,
    Box,
    Chip,
    IconButton,
    Menu,
    Typography,
    MenuItem,
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    Divider,
    Switch,
    Link as ExternalLink,
    ListItemAvatar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    //TextField,
    Alert
} from "@mui/material";
import {
    Create as CreateIcon,
    MoreVert as MoreVertIcon,
    Logout as LogoutIcon,
    Settings as SettingsIcon,
    LightMode as LightModeIcon,
    Close as CloseIcon,
    Straighten as StraightenIcon,
    Cookie as CookieIcon
} from "@mui/icons-material";

class UserMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchor: null,
            showSettingsModal: false,
            currentAlert: null,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleToggleAlert = this.handleToggleAlert.bind(this);
        this.handleToggleSettingsModal = this.handleToggleSettingsModal.bind(this);
    }

    handleClick(event) {
        this.setState({ anchor: event.currentTarget });
    }

    handleClose() {
        this.setState({ anchor: null });
    }

    handleToggleAlert(alertType) {
        this.setState((prevState) => ({
            currentAlert: prevState.currentAlert === alertType ? null : alertType,
        }));
    }

    handleToggleSettingsModal(open) {
        this.setState({ 
            anchor: open ? null : this.state.anchor, 
            showSettingsModal: open, 
            currentAlert: open ? this.state.currentAlert : null 
        });
    }

    handleUnitChange = (event) => {
        let { athleteId } = this.props;
        fetch('api/saveUnitPreference', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                encryptedId: athleteId,
                metric: event.target.checked
            })
        }).then(() => window.location.reload());
    }

    renderAlertItem(alertSeverity, primaryText, secondaryText, buttonText, alertMessage, confirmLink) {
        return (
            <>
                <ListItem sx={{ px: { xs: 0, sm: 2} }}>
                    <ListItemText primary={primaryText} secondary={secondaryText} />
                    <Button size="small" onClick={() => this.handleToggleAlert(primaryText)} color="error" variant="outlined">
                        {buttonText}
                    </Button>
                </ListItem>
                {this.state.currentAlert === primaryText && (
                    <Alert severity={alertSeverity} sx={{ mt: 2 }}>
                        <Typography variant="body2">
                            {alertMessage}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button size="small" onClick={() => this.setState({ currentAlert: null })} color="error">
                                Cancel
                            </Button>
                            <Button size="small" component={RouterLink} to={confirmLink} color="error" sx={{ ml: 2 }}>
                                Confirm
                            </Button>
                        </Box>
                    </Alert>
                )}
            </>
        );
    }

    render() {
        let {
            anchor,
            showSettingsModal,
        } = this.state;
        let {
            athleteImageSrc,
            isAdmin,
            isDarkTheme,
            athleteFirstName,
            athleteLastName,
            athleteOffice,
            athleteTitle,
            athleteMetric,
            toggleTheme
        } = this.props;
        let verb = process.env.REACT_APP_TARGET === "www" ? "Publish" : "Create";
        return (
            <>
                <IconButton disableTouchRipple onClick={(ev) => this.handleClick(ev)}>
                    <Avatar
                        src={
                            athleteImageSrc
                                ? athleteImageSrc
                                : "https://d3nn82uaxijpm6.cloudfront.net/assets/avatar/athlete/large-800a7033cc92b2a5548399e26b1ef42414dd1a9cb13b99454222d38d58fd28ef.png"
                        }
                    />
                    <MoreVertIcon sx={{ color: "white!important" }} />
                </IconButton>
                <Menu
                    anchorEl={anchor}
                    keepMounted
                    open={Boolean(anchor)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    onClose={this.handleClose}
                >
                    <MenuItem
                        disabled
                        sx={{
                            pt: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            alignItems: "center",
                            opacity: 1,
                            "&.Mui-disabled": {
                                opacity: 1,
                            },
                        }}
                    >
                        <Typography
                            variant="body2"
                            display="block"
                            fontWeight="bold"
                            component="span"
                        >
                            {athleteFirstName} {athleteLastName}
                        </Typography>
                        <Typography component="span" variant="caption">
                            {athleteOffice} &#8226; {athleteTitle}
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        sx={{
                            mx: 'auto',
                            p: 0,
                            borderRadius: '16px',
                            width: 'fit-content',
                        }}
                        onClick={() => this.handleToggleSettingsModal(true)}
                    >
                        <Chip label="Manage your account" icon={<SettingsIcon />} variant="outlined" />
                    </MenuItem>
                    <Divider />
                    {isAdmin && [
                        <MenuItem key="usermenu_admin_challenges" component={RouterLink} to="/admin">
                            <ListItemIcon>
                                <CreateIcon />
                            </ListItemIcon>
                            <ListItemText>{verb} Challenges</ListItemText>
                        </MenuItem>,
                        <Divider key="divider_admin" />
                    ]}
                    <MenuItem>
                        <ListItemIcon><LightModeIcon fontSize="small" /></ListItemIcon>
                        <ListItemText>Dark Theme</ListItemText>
                        <Switch
                            checked={isDarkTheme}
                            onChange={toggleTheme}
                            inputProps={{ "aria-label": "Dark mode switch" }}
                            size="small"
                            name="darkThemeSwitch"
                        />
                    </MenuItem>
                    <MenuItem>
                        <ListItemIcon><StraightenIcon fontSize="small" /></ListItemIcon>
                        <ListItemText>Metric Units</ListItemText>
                        <Switch
                            checked={athleteMetric}
                            onChange={this.handleUnitChange}
                            inputProps={{ "aria-label": "Unit of Measure switch" }}
                            size="small"
                            name="unitMeasureSwitch"
                        />
                    </MenuItem>
                    <MenuItem component={ExternalLink} href="#" className="optanon-toggle-display">
                        <ListItemIcon><CookieIcon fontSize="small" /></ListItemIcon>
                        <ListItemText>Cookie Settings</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem component={RouterLink} to="/logout/0">
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            Logout
                        </ListItemText>
                    </MenuItem>
                </Menu>

                <Dialog open={showSettingsModal} onClose={() => this.handleToggleSettingsModal(false)} fullWidth maxWidth="sm">
					<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Typography variant="span">Manage Account</Typography>
						<IconButton
							color="inherit"
							onClick={() => this.handleToggleSettingsModal(false)}
							aria-label="close settings"
						>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
                    <DialogContent>
                        <List sx={{ "& .MuiButton-root": { width: 100 }}}>
                            <ListItem sx={{ px: { xs: 0, sm: 2} }}>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={athleteFirstName + " " + athleteLastName}
                                        src={
											athleteImageSrc
												? athleteImageSrc
												: "https://d3nn82uaxijpm6.cloudfront.net/assets/avatar/athlete/large-800a7033cc92b2a5548399e26b1ef42414dd1a9cb13b99454222d38d58fd28ef.png"
										}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={athleteFirstName + " " + athleteLastName} secondary={athleteOffice + " - " + athleteTitle} primaryTypographyProps={{ fontWeight: "bold" }} />
                            </ListItem>
                            <Divider />
                            <ListItem sx={{ px: { xs: 0, sm: 2} }}>
                                <ListItemText primary="Update profile" secondary="Refresh your Deloitte profile data" />
                                <Button size="small" component={RouterLink} to="/updateProfile" color="primary" variant="outlined">
                                    Update
                                </Button>
                            </ListItem>
                            {this.renderAlertItem(
                                'error',
                                'Deactivate account',
                                'Mark yourself as inactive',
                                'Quit',
                                'Proceeding will mark you as inactive. We will stop synchronizing your data, but your history will be saved. To return to the challenge, reconnect your account via Strava and we will refresh your data.',
                                '/logout/1'
                            )}
                            {this.renderAlertItem(
                                'error',
                                'Delete account',
                                'Delete all your data',
                                'Delete',
                                'Proceeding will delete your account and all your data from this application. To return to the challenge, reconnect your account via Strava and we will reload your activities; however, all previous challenge accomplishments will be lost.',
                                '/logout/2'
                            )}
                            {/*
                            <Divider sx={{ my: 1 }} />
                            <ListItem sx={{ px: { xs: 0, sm: 2} }}>
                                <ListItemText 
                                    primary={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            Language
                                            <Chip label="Coming soon" size="small" sx={{ ml: 1 }} />
                                        </Box>
                                    } 
                                    secondary="Select the app language" 
                                />
                                <TextField select size="small" value={"lang1"} disabled>
                                    <MenuItem key={1} value="lang1">
                                        English
                                    </MenuItem>
                                </TextField>
                            </ListItem>
                            */}
                        </List>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default UserMenu;