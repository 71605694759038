import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Card,
    CardHeader,
	CardContent,
	Typography,
	Paper,
} from "@mui/material";
import FormatAmount from "./FormatAmount.tsx";

const noDimension = {
	id: -1,
	name: "No dimension",
	stravaName: "No dimension",
	conversionFactor: 1,
	unitShort: "",
	unitLong: "",
	unitMetric: ""
};

export default function GlobalData(props) {
	return (
		<Card className="animate-content-card-up">
			<CardHeader
				title={
					<Typography component="h2" fontWeight="bold">
						Year-on-Year Comparison
					</Typography>
				}
			/>
			{
				<>
					<CardContent sx={{ py: 0 }}>
						<Typography variant="caption">
							Aggregate exercise statistics from{" "}
							<strong>{props.athleteCount.registered}</strong> registered participants out of the{" "}
							<strong>{props.athleteCount.clubMembers}</strong> club members in Strava
						</Typography>
					</CardContent>
					<Paper
						sx={{
							width: "100%",
							overflow: "hidden",
							marginTop: "16px",
						}}
					>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table" size="small">
								<TableHead>
									<TableRow>
										<TableCell
											align="left"
										>
											Total
										</TableCell>
										<TableCell
											align="right"
										>
											This Year to Date
										</TableCell>
										<TableCell
											align="right"
										>
											Last Year to Date
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Object.keys(props.totals).map((dimension, i) => (
										<TableRow
											key={i}
											sx={{
												"&:last-child td, &:last-child th": {
													border: 0,
												},
											}}
										>
											<TableCell align="left">{dimension}</TableCell>
											<TableCell align="right">
												<FormatAmount
													value={props.totals[dimension]}
													dimension={
														["Trophies", "Activities"].includes(dimension) 
															? noDimension
															: props.dimensions.find((dim) => dim.name === dimension)
}
													isMetric={props.isMetric}
													decimals={0}
												/>
											</TableCell>
											<TableCell align="right">
											<FormatAmount
													value={props.lastYear[dimension]}
													dimension={
														["Trophies", "Activities"].includes(dimension) 
															? noDimension
															: props.dimensions.find((dim) => dim.name === dimension)
}
													isMetric={props.isMetric}
													decimals={0}
												/>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</>
			}
		</Card>
	);
}
