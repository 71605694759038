import React, { useState } from "react";
import {
	Select,
	MenuItem,
	Card,
	CardHeader,
	CardContent,
	Typography,
	List,
	ListItem,
	Stack,
	LinearProgress
} from "@mui/material";

const popularActivitiesBarColor = "#83BC25";
const explanation = {
	Activity: "Percentage that each discipline represents of total activities.",
	Athlete: "Percentage of athletes that completed at least one activity on each discipline."
};

export default function GlobalData(props) {
	const [popularChoice, setPopularChoice] = useState("Activity");
	
	const handleChangePopularChoice = (event) => {
		setPopularChoice(event.target.value);
	};

	return (
		<Card className="animate-content-card-up">
			<CardHeader
				action={
					<Select
						value={popularChoice}
						onChange={handleChangePopularChoice}
						size="small"
					>
						{Object.keys(props.popular).map((key, i) => (
							<MenuItem key={i} value={key}>
								{key}
							</MenuItem>
						))}
					</Select>
				}
				title={
					<Typography component="h2" fontWeight="bold">
						Popular Disciplines
					</Typography>
				}
			/>
			<CardContent sx={{ pt: 0 }}>
				<Typography variant="caption">
					{explanation[popularChoice]}
				</Typography>
				<List dense sx={{ pb: 0, pt: 2 }}>
					{props.popular[popularChoice].map((item, i) => (
						<ListItem key={i}>
							<Stack
								direction="row"
								justifyContent="space-between"
								sx={{ width: "85%", alignItems: "center" }}
							>
								<Typography 
									variant="caption"
									sx={{ width: "15% "}}
									noWrap
								>
									{item.discipline}
								</Typography>
								<LinearProgress
									sx={{
										color: popularActivitiesBarColor,
										height: "15px",
										minWidth: "80%",
									}}
									value={item.percentage * 100}
									color="inherit"
									variant="determinate"
								/>
							</Stack>
							<Stack
								direction="row"
								justifyContent="flex-end"
								sx={{ width: "15%", alignItems: "center" }}
							>
								<Typography variant="caption">
									{Math.floor(item.percentage * 100) + '%'}
								</Typography>
							</Stack>
						</ListItem>
					))
					}
				</List>
			</CardContent>
		</Card>
	);
}
