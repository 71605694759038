import React from "react";
import {
	Container,
	Grid,
	Card,
	CardContent,
	Avatar,
	Typography,
	Divider,
	Box,
	LinearProgress,
	Tabs,
	Tab,
	Link,
} from "@mui/material";
import { Team, Donut, WeeklyBar, FormatAmount } from "../components";
import moment from "moment";

const PersonalPage = ({
	data,
	athleteId,
	mainIndex,
	mainChallengeAmount,
	daysRemaining,
	graphPercentage,
	icons,
	toggle,
	labelsDiv,
	displayActivities,
	isDarkTheme,
	chartColor,
	dimLabels,
	dimIndex,
	tSelected,
	oSelected,
	colors,
	lastYearColor,
	updateMyTeam,
	buildPaceMessage,
	toggleSwitch,
	orderColorsByType,
	resetTooltip,
}) => {
	return (
		<Container sx={{ my: 2 }} maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<Grid container sx={{ mb: "24px" }} spacing={2}>
						<Grid item xs={12}>
							<Card
								color="secondary"
								className="animate-content-card-up"
								sx={{
									mt: "36px",
									display: "flex",
									flexDirection: "column",
									overflow: "visible!important",
								}}
							>
								<CardContent sx={{ textAlign: "center" }}>
									<Avatar
										alt={data.athlete.firstName}
										src={data.athlete.pictureURL}
										sx={{ width: 50, height: 50, m: "-50px auto 0" }}
									/>
									<Typography component="h2" fontWeight="bold" sx={{ mb: 1 }}>
										{data.athlete.firstName} {data.athlete.lastName}
									</Typography>
									<Grid container sx={{ mt: 2 }}>
										<Grid item xs={3}>
											<Typography variant="body1" fontWeight={700}>
												{data.totalActivitiesYTD}
											</Typography>
											<Typography variant="caption" sx={{ display: "block" }}>
												Activities
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Team
												myTeam={data.myTeam}
												update={updateMyTeam}
												athleteId={athleteId}
											/>
											<Typography variant="caption" sx={{ display: "block" }}>
												Team
											</Typography>
										</Grid>
										<Grid item xs={3}>
											<Typography variant="body1" fontWeight={700}>
												{data.pastAccomplishments.length}
											</Typography>
											<Typography variant="caption" sx={{ display: "block" }}>
												Achievements
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
								<Divider />
								<CardContent sx={{ textAlign: "center" }}>
									<Grid container>
										<Grid item xs={6}>
											<Typography variant="body1" fontWeight={700}>
												{data.deloitteProfile.office}
											</Typography>
											<Typography variant="caption" sx={{ display: "block" }}>
												Office
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography variant="body1" fontWeight={700}>
												{data.deloitteProfile.title}
											</Typography>
											<Typography variant="caption" sx={{ display: "block" }}>
												Title
											</Typography>
										</Grid>
									</Grid>
								</CardContent>
								<Divider />
								<CardContent sx={{ mt: "auto" }}>
									<Grid
										container
										direction="row"
										justifyContent="space-between"
										alignItems="center"
									>
										<Typography variant="overline">Yearly Challenge</Typography>
										{buildPaceMessage()}
									</Grid>
									<Box sx={{ display: "flex", my: 1 }}>
										<Typography variant="overline" sx={{ flexGrow: 1 }}>
											<FormatAmount
												value={data.totalYearByDimension[mainIndex]}
												dimension={data.dimensions[mainIndex]}
												isMetric={data.athlete.metric}
												decimals={2}
												skipUnit
											/>{" / "}
											<FormatAmount
												value={mainChallengeAmount}
												dimension={data.dimensions[mainIndex]}
												isMetric={data.athlete.metric}
												decimals={0}
											/>
										</Typography>
										<Typography variant="overline">
											{daysRemaining} days remaining
										</Typography>
									</Box>
									<LinearProgress
										className="challenge-progress-bar"
										variant="determinate"
										value={graphPercentage}
									/>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12}>
							<Card className="animate-content-card-up">
								<CardContent>
									<Typography component="h2" fontWeight="bold" sx={{ mb: 1 }}>
										Latest Activities
									</Typography>
									{displayActivities}
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					md={8}
					sx={{ mt: { xs: 0, md: "36px" }, mb: "24px" }}
				>
					<Card
						className="animate-content-card-up"
						sx={{
							mb: 3,
							height: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
						}}
					>
						<Tabs value={toggle} variant="fullWidth" onChange={toggleSwitch}>
							{icons.map((icon, i) => (
								<Tab key={i} value={i + 1} icon={icon} />
							))}
						</Tabs>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<CardContent>
									<Box sx={{ display: "flex", flexDirection: "column" }}>
										<Typography
											sx={{ fontWeight: "bold", lineHeight: 1 }}
											variant="overline"
										>
											This Year
										</Typography>
										<Typography variant="caption" sx={{ marginBottom: "10px" }}>
											{data.whatYear}
										</Typography>
									</Box>
									<Donut
										year={data.yearlySummaryByDimension[dimIndex]}
										totalYear={data.totalYearByDimension[dimIndex]}
										dimension={data.dimensions[dimIndex]}
										ranking={
											data.athleteMainRanking.filter(
												(rank) =>
													rank.dimensionId === data.dimensions[dimIndex].id
											)[0].position
										}
										backColor={isDarkTheme ? chartColor.dark : chartColor.light}
										colors={orderColorsByType(dimLabels)}
										lastYTD={
											data.athleteCombinedLastYearData.lastYTD[dimIndex].amount
										}
										selected={tSelected}
										resetTooltip={resetTooltip}
										isMetric={data.athlete.metric}
									/>
								</CardContent>
							</Grid>
							<Grid item xs={12} md={6}>
								<CardContent>
									<Box sx={{ display: "flex", flexDirection: "column" }}>
										<Typography
											sx={{ fontWeight: "bold", lineHeight: 1 }}
											variant="overline"
										>
											This Week
										</Typography>
										<Typography variant="caption" sx={{ marginBottom: "10px" }}>
											{moment(data.sixDaysAgo, "MMMM DD").format("MMM DD") +
											" - " +
											moment(data.today, "MMMM DD").format("MMM DD")}
										</Typography>
									</Box>
									<WeeklyBar
										dimIndex={toggle}
										weekdays={data.weekdays}
										dimensions={data.dimensions}
										dailyTotals={data.dailyTotals}
										labels={dimLabels}
										foreColor={isDarkTheme ? chartColor.light : chartColor.dark}
										backColor={isDarkTheme ? chartColor.dark : chartColor.light}
										colors={colors}
										sixDaysAgo={data.sixDaysAgo}
										today={data.today}
										lastYTD={data.athleteCombinedLastYearData.weekStartLastYear}
										lastYearColor={lastYearColor}
										selected={oSelected}
										resetTooltip={resetTooltip}
										isMetric={data.athlete.metric}
									/>
								</CardContent>
							</Grid>
						</Grid>
						<CardContent>
							<Grid container spacing={1}>
								{labelsDiv}
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			<Card className="animate-content-card-up">
				<Box sx={{ height: 12 }} className="bg-ukraine-blue" />
				<Box sx={{ height: 12 }} className="bg-ukraine-yellow" />
				<CardContent sx={{ padding: "10px!important" }}>
					<Typography variant="body2">
						Deloitte Digital stands in solidarity with Ukraine against the
						Russian invasion.
						<Link
							variant="text"
							style={{ marginLeft: "5px", fontWeight: "bold" }}
							href="https://war.ukraine.ua/support-ukraine/"
						>
							Help now
						</Link>
					</Typography>
				</CardContent>
			</Card>
		</Container>
	);
};

export default PersonalPage;
