import React from "react";
import FormatAmount from "./FormatAmount.tsx";
import {
	Grid,
	Card,
	CardMedia,
	CardContent,
	Typography,
	Button,
	IconButton,
	Chip,
	Box,
	LinearProgress,
	AvatarGroup,
	Avatar,
	Badge,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListSubheader,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import LayersIcon from "@mui/icons-material/Layers";
import RouteIcon from "@mui/icons-material/Route";
import TimerIcon from "@mui/icons-material/Timer";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import CircleIcon from "@mui/icons-material/Circle";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import QuizIcon from '@mui/icons-material/Quiz';
import { enqueueSnackbar } from "notistack";

class ChallengeCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = { teamMembers: [], open: false };
		this.handleClick = this.handleClick.bind(this);
		this.handleShowNotification = this.handleShowNotification.bind(this);
		this.updateParticipants = this.updateParticipants.bind(this);
		this.handleDialogClick = this.handleDialogClick.bind(this);
		this.chatWithTeam = this.chatWithTeam.bind(this);
	}

	componentDidMount() {
		let { myTeam, challengeData } = this.props;
		if (challengeData.isTeam && myTeam.id > 0) {
			if (!challengeData.isDeloitteAttribute || !challengeData.isFuture) {
				this.updateParticipants(myTeam.id, challengeData.challengeId, challengeData.isDeloitteAttribute);
			}
		}
	}

	componentDidUpdate(prevProps) {
		let newTeam = prevProps.myTeam !== this.props.myTeam;
		let newJoin =
			prevProps.challengeData.updateParticipants !==
			this.props.challengeData.updateParticipants;
		if (newTeam || newJoin) {
			if (this.props.myTeam.id === 0) {
				this.setState({ teamMembers: [] });
			} else {
				let { myTeam, challengeData } = this.props;
				if (challengeData.isTeam) {
					if (!challengeData.isDeloitteAttribute || !challengeData.isFuture) {
						this.updateParticipants(myTeam.id, challengeData.challengeId, challengeData.isDeloitteAttribute);
					} else {
						this.setState({ teamMembers: [] });	
					}
				}
			}
		}
	}

	updateParticipants(teamId, challengeId, isDeloitte) {
		let { challengeData, dimensions, isMetric } = this.props;
		let baseRequest = "api/team/getMembers?teamId=" + teamId;
		const urls = [
			baseRequest + "&challengeId=" +	challengeId + "&isActive=" + !challengeData.isFuture,
			baseRequest
		];
		//Fix for issue 221
		if (isDeloitte) {
			urls.pop();
		}
		Promise.all(
			urls.map((url) =>
				fetch(url, { accept: "application/json" }).then((resp) => resp.json())
			)
		).then((data) => {
			for (let i = 0; i < urls.length; i++) {
				if (i === 1) {
					data[i].sort((a, b) =>
						a.name.toUpperCase() > b.name.toUpperCase()
							? 1
							: a.name.toUpperCase() < b.name.toUpperCase()
							? -1
							: 0
					);
				} else {
					data[i].sort((a, b) =>
						a.amount < b.amount ? 1 : a.amount > b.amount ? -1 : 0
					);
				}
				data[i] = data[i].map((member) => {
					return {
						name: member.name,
						pictureURL: member.pictureURL,
						signedUp: i === 0,
						amount: i === 1 ? 0 : 
							FormatAmount({
								value: member.amount,
								dimension: dimensions.find((dim) => dim.name === challengeData.challengeType),
								isMetric: isMetric,
								decimals: 2
							}),
						email: member.email
					};
				});
			}
			let teamMembers = (data[1] && data[1].length > 0) ? data[0].concat(
				data[1].filter(
					member =>
						data[0].findIndex(
							participant =>
								participant.email && participant.email.length > 0 && member.email && member.email.length > 0 ?
									participant.email === member.email
									:
									participant.name === member.name &&
									participant.pictureURL === member.pictureURL
						) < 0
				)
			) : data[0];
			this.setState({
				teamMembers: teamMembers
			});
		});
	}

	handleShowNotification(notificationText, notificationVariant) {
		enqueueSnackbar(notificationText, {
			variant: notificationVariant,
		});
	}

	handleClick() {
		let { athleteId, myTeam, challengeData } = this.props;
		if (!challengeData.isTeam || challengeData.isDeloitteAttribute || myTeam.id > 0) {
			const requestOptions = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					athleteId: athleteId,
					challengeId: challengeData.challengeId,
					utcOffset: new Date().getTimezoneOffset(),
				}),
			};
			fetch("api/updateAthleteSignUps", requestOptions)
				.then((resp) => resp.json())
				.then((resp) => this.props.onSignUp(challengeData.challengeId, resp));
		} else {
			this.handleShowNotification(
				"You need to join a team to enter a team challenge",
				"error"
			);
		}
	}

	handleDialogClick(toggle) {
		this.setState({ open: toggle });
	}

	chatWithTeam() {
		let { challengeData, myTeam } = this.props;
		let { teamMembers } = this.state;
		let message = "We are participating in the '" + challengeData.challengeName + 
			"' team challenge together, as the '" + myTeam.name + "' team. Let's get organized!";
		let addresses = teamMembers.map(member => member.email).toString();
		let url = encodeURI("msteams:/l/chat/0/0?users=" + addresses + "&message=" + message);
		window.open(url, "_blank");
	}

	render() {
		let { challengeData, dimensions, myTeam, isMetric } = this.props;
		let { teamMembers, open } = this.state;
		let challengeImage = "";
		if (typeof challengeData.image !== "undefined") {
			challengeImage = "../img/" + challengeData.image;
		}
		let challengeDates =
			challengeData.startDate !== challengeData.endDate
				? challengeData.startDate + " - " + challengeData.endDate
				: challengeData.startDate;
		let challengeGoal = FormatAmount({
			value: challengeData.totalGoal,
			dimension: dimensions.find((dim) => dim.name === challengeData.challengeType),
			isMetric: isMetric,
			decimals: 0
		});
		let totalAchieved = FormatAmount({
			value: challengeData.totalAchieved,
			dimension: dimensions.find((dim) => dim.name === challengeData.challengeType),
			isMetric: isMetric,
			decimals: 2,
			skipUnit: true
		});
		let percent =
			challengeData.progressPercentage > 100
				? 100
				: challengeData.progressPercentage;
		let remainingDays = null;
		if (challengeData.signedUp && !challengeData.isFuture) {
			if (challengeData.achieved) {
				remainingDays = "Completed";
			} else {
				if (challengeData.remainingDays === 0) {
					remainingDays = "Last day";
				} else {
					if (challengeData.remainingDays === 1) {
						remainingDays = "1 day";
					} else {
						remainingDays = challengeData.remainingDays + " days";
					}
					remainingDays += " remaining";
				}
			}
		}
		let progressSection =
			challengeData.signedUp && !challengeData.isFuture ? (
				<>
					<Box sx={{ display: "flex", mb: 1 }}>
						<Typography variant="overline" sx={{ flexGrow: 1 }}>
							{totalAchieved} / {challengeGoal}
						</Typography>
						<Typography variant="overline">{remainingDays}</Typography>
					</Box>
					<LinearProgress
						className="challenge-progress-bar"
						variant="determinate"
						value={percent}
					/>
				</>
			) : (
				""
			);
		let joinButton = challengeData.signedUp ? (
			<Button variant="contained" size="small" disabled color="secondary">
				Participating
			</Button>
		) : (
			<Button
				color="primary"
				variant="contained"
				size="small"
				onClick={this.handleClick}
			>
				Join
			</Button>
		);
		let challengeIcon;
		switch (challengeData.challengeType) {
			case "Distance":
				challengeIcon = <RouteIcon />;
				break;
			case "Duration":
				challengeIcon = <TimerIcon />;
				break;
			default:
				challengeIcon = <FilterHdrIcon />;
		}
		let challengeDisciplines = challengeData.disciplines.join(", ");
		if (challengeDisciplines === "All") {
			challengeDisciplines += " disciplines";
		}
		let singleIcon = challengeData.isSingle ? (
			<LayersClearIcon sx={{ fontSize: 16, verticalAlign: "middle" }} />
		) : (
			<LayersIcon sx={{ fontSize: 16, verticalAlign: "middle" }} />
		);
		let singleLabel = challengeData.isSingle
			? "Single Activity"
			: "Multiple Activities";
		let avatars =
			challengeData.isTeam &&
			typeof teamMembers !== "undefined" &&
			teamMembers.length > 0 ? (
				<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', ml: '5px', mb: 1 }}>
					<AvatarGroup max={7}>
						{teamMembers.map((member, i) => (
							<Badge
								key={i}
								overlap="circular"
								anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
								badgeContent={
									<CircleIcon
										sx={{
											fontSize: 6,
											color: member.signedUp ? "green" : "red",
										}}
									/>
								}
							>
								<Avatar alt={member.name} src={member.pictureURL} />
							</Badge>
						))}
					</AvatarGroup>
					<IconButton aria-label="team details" onClick={() => this.handleDialogClick(true)} size="large">
						<QuizIcon fontSize='inherit' color='primary'/>
					</IconButton>
				</Box>
			) : null;
		let content =
			challengeData.isTeam &&
			typeof teamMembers !== "undefined" &&
			teamMembers.length > 0 ? (
				<List dense sx={{ py: 0 }}>
					<li key={1}>
						<List dense>
							<ListSubheader>
								Participating (
								{teamMembers.filter((member) => member.signedUp).length})
							</ListSubheader>
							{teamMembers
								.filter((member) => member.signedUp)
								.map((member, index) => {
									return (
										<ListItem key={index}>
											<ListItemAvatar>
												<Badge
													overlap="circular"
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "right",
													}}
													badgeContent={
														<CircleIcon sx={{ fontSize: 6, color: "green" }} />
													}
												>
													<Avatar alt={member.name} src={member.pictureURL} />
												</Badge>
											</ListItemAvatar>
											<ListItemText primary={member.name} />
											<ListItemText
												primary={member.amount}
												sx={{ textAlign: "end", fontWeight: "bold" }}
											/>
										</ListItem>
									);
								})}
						</List>
					</li>
					<li key={2}>
						<List dense sx={{ py: 0 }}>
							<ListSubheader>
								Not Participating (
								{teamMembers.filter((member) => !member.signedUp).length})
							</ListSubheader>
							{teamMembers
								.filter((member) => !member.signedUp)
								.map((member, index) => {
									return (
										<ListItem key={index} sx={{ my:3 }}>
											<ListItemAvatar>
												<Badge
													overlap="circular"
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "right",
													}}
													badgeContent={
														<CircleIcon sx={{ fontSize: 6, color: "red" }} />
													}
												>
													<Avatar alt={member.name} src={member.pictureURL} />
												</Badge>
											</ListItemAvatar>
											<ListItemText primary={member.name} />
										</ListItem>
									);
								})}
						</List>
					</li>
				</List>
			) : null;
		let personPeopleIcon = challengeData.isTeam ? (
			<PeopleIcon />
		) : (
			<PersonIcon />
		);
		return (
			<Card
				key={challengeData.challengeId}
				className="challenge-card"
			>
				<Grid container>
					<Grid item xs={12}>
						<Box
							sx={{ position: "relative", height: "100%", overflow: "hidden" }}
						>
							<Chip
								className="chip-participant"
								color="error"
								size="small"
								label={`${challengeData.enrolledAthleteCount} ${
									challengeData.enrolledAthleteCount === 1
										? "participant"
										: "participants"
								}`}
							/>
							<CardMedia
								component="img"
								height="100%"
								image={challengeImage}
								alt="Challenge Type"
								sx={{ borderRadius: 0 }}
							/>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<CardContent>
							<Grid container wrap="nowrap" justifyContent="space-between">
								<Grid item zeroMinWidth>
									<Typography fontSize={"1.25rem"} fontWeight="bold" noWrap>
										{challengeData.challengeName}
									</Typography>
								</Grid>
								<Grid item>{joinButton}</Grid>
							</Grid>
							<Grid container wrap="nowrap" justifyContent="space-between" sx={{ mt: 0.5 }}>
								<Typography variant="overline">{challengeDisciplines}</Typography>
								{challengeData.isTeam && 
									<Typography variant="overline">{myTeam.name}</Typography>
								}
							</Grid>
						</CardContent>
						<CardContent sx={{ pt: 0 }}>
							<Dialog
								open={open}
								aria-labelledby="responsive-dialog-title"
								fullWidth
							>
								<DialogTitle id="responsive-dialog-title">
									Challenge Team Roster
								</DialogTitle>
								<DialogContent className="DialogContent-scroll">
									{content}
								</DialogContent>
								<DialogActions sx={{ mb: 1, mr: 1 }}>
									<Button
										variant="contained"
										onClick={this.chatWithTeam}
									>
										Chat with team
									</Button>
									<Button
										variant="contained"
										onClick={() => this.handleDialogClick(false)}
									>
										Close
									</Button>
								</DialogActions>
							</Dialog>
							<Grid container spacing={0.5}>
								<Grid item xs="auto">
									<Chip
										size="small"
										icon={personPeopleIcon}
										label={challengeData.isTeam ? "Team" : "Solo"}
									/>
								</Grid>
								<Grid item xs="auto">
									<Chip
										size="small"
										icon={challengeIcon}
										label={challengeGoal}
									/>
								</Grid>
								<Grid item xs="auto">
									<Chip size="small" icon={singleIcon} label={singleLabel} />
								</Grid>
							</Grid>
							<Typography
								sx={{
									display: "flex",
									alignItems: "center",
									my: 1,
									fontSize: "14px",
								}}
							>
								<TodayIcon
									sx={{ fontSize: "18px!important", mr: 0.5, color: "#91B942" }}
								/>
								{" " + challengeDates}
							</Typography>
							<Typography variant="body2" sx={{ mt: "8px" }}>
								{challengeData.description}
							</Typography>
						</CardContent>
						{challengeData.signedUp ? (
							<CardContent sx={{ pt: 0, mt: "auto" }}>
								{avatars}
								{progressSection}
							</CardContent>
						) : (
							""
						)}
					</Grid>
				</Grid>
			</Card>
		);
	}
}

export default ChallengeCard;
