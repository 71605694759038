import React from 'react';

interface Props {
	value: number;
	dimension: {
		id: number;
		name: string;
		stravaName: string;
		conversionFactor: number;
		unitShort: string;
		unitLong: string;
		unitMetric: string;
	};
	isMetric: boolean;
	decimals: number;
	skipUnit?: boolean;
}

const FormatAmount: React.FC<Props> = ({ value, dimension, isMetric, decimals, skipUnit = false }) => {
		let response = "";
		let locale = navigator.language;
		if (dimension.name === 'Duration') {
			if (!skipUnit) {
				const mins = Math.floor((value * 60) % 60);
				const hours = Math.floor(value);
				response = 
					hours > 0 ?
						hours.toLocaleString(locale, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' ' +
						(isMetric ? dimension.unitMetric : dimension.unitShort)
					: 
					'';
				if (mins > 0) {
					response += ` ${mins.toString().padStart(2, "0")} min`;
				} else {
					response += (hours === 0 ? '0 ' + dimension.unitShort : '');
				}
			} else {
				response = value.toLocaleString(locale, { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
			}
		} else {
			let divisor = isMetric && dimension.id === 1 ? 1000 : 1;
			let multiplier = isMetric ? dimension.conversionFactor : 1;
			let displayValue = ((value / divisor) * multiplier).toFixed(decimals);
			response = parseFloat(displayValue).toLocaleString(locale, { minimumFractionDigits: decimals, maximumFractionDigits: decimals }) +
				(skipUnit ? 
					'' 
					: 
					' ' + (isMetric ? dimension.unitMetric : dimension.unitShort)
				);
		}
		return response;
};

export default FormatAmount;