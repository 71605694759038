import * as React from "react";
import {
	AppBar,
	Box,
	Link,
	Toolbar,
	Typography,
	Container,
	Tooltip,
} from "@mui/material";

import UserMenu from "./UserMenu.js";

const MenuBar = (props) => {
	return (
		<AppBar position="static">
			<Container maxWidth="lg">
				<Toolbar disableGutters>
					<img
						className="logo"
						src="../img/logo/deloitte_digital.png"
						alt="Deloitte Digital Logo"
					/>
					<Tooltip title="We support Ukraine">
						<Box sx={{ width: 30, mr: 1 }} flex="none">
							<Box sx={{ height: 10 }} className="bg-ukraine-blue" />
							<Box sx={{ height: 10 }} className="bg-ukraine-yellow" />
						</Box>
					</Tooltip>
					<Typography
						fontWeight="bold"
						variant="h1"
					>
						<Link href="/" color="inherit" underline="none">
							Global Challenge
						</Link>
					</Typography>
					{props.athleteFirstName && (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								flexGrow: 0,
								ml: "auto",
							}}
						>
							<UserMenu
								athleteId={props.athleteId}
								athleteImageSrc={props.athleteImageSrc}
								isAdmin={props.isAdmin}
								toggleTheme={props.toggleTheme}
								isDarkTheme={props.isDarkTheme}
								athleteFirstName={props.athleteFirstName}
								athleteLastName={props.athleteLastName}
								athleteOffice={props.athleteOffice}
								athleteTitle={props.athleteTitle}
								athleteMetric={props.athleteMetric}
							/>
						</Box>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
};
export default MenuBar;
