import React from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import { Chart, CategoryScale, LinearScale, BarElement, Tooltip, LineController, LineElement, PointElement, Legend } from 'chart.js';
import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import FormatAmount from "./FormatAmount.tsx";

Chart.register(CategoryScale, LinearScale, BarElement, Tooltip, LineController, LineElement, PointElement, Legend);

class GlobalWeeklyChart extends React.Component {
	constructor(props) {
		super(props);
		this.configData = this.configData.bind(this);
		this.configOptions = this.configOptions.bind(this);
		this.chart = null;
	}

	componentDidMount() {
		let { dimensions } = this.props;
		let baseURL = "";
		switch (process.env.REACT_APP_TARGET) {
			case "qa":
				baseURL = process.env.REACT_APP_ORACLE_QA;
				break;
			case "dev":
				baseURL = process.env.REACT_APP_ORACLE_DEV;
				break;
			case "www":
				baseURL = process.env.REACT_APP_ORACLE_PROD;
				break;
			default:
				baseURL = process.env.REACT_APP_ORACLE_QA;
		};
		baseURL += "/ords/admin/DB/getWeeklyStats/";
		let urls = dimensions.map((dim) => baseURL + dim.id);
		Promise.all(
			urls.map((url) =>
				fetch(url, { accept: "application/json" }).then((resp) => resp.json())
			)
		).then((data) => {
			let averages = data
				.map((arr) => arr.map((elem) => elem.amount))
				.map(
					(serie) =>
						serie.reduce(function (x, y) {
							return x + y;
						}, 0) / serie.length
				);
			let min = Math.min(...averages);
			let scales = averages.map((avg) => Math.round(avg / min));
			let labels = data[0].map((elem) => moment(elem.date).format("ll"));
			let series = data.map((arr) =>
				arr.map((elem) => Math.round(elem.amount))
			);
			let values = data.map((arr, i) =>
				arr.map((elem) => elem.amount / scales[i])
			);
			let chartCanvas = ReactDOM.findDOMNode(this.chart);
			let chartValues = {
				type: "bar",
				data: this.configData(values, labels),
				options: this.configOptions(),
			};
			let myChart = new Chart(chartCanvas, chartValues);
			this.setState({ series: series, chart: myChart });
		});
	}

	componentDidUpdate(prevProps) {
		let { dimensions, colors, foreColor } = this.props;
		if (JSON.stringify(dimensions) !== JSON.stringify(prevProps.dimensions) ||
			JSON.stringify(colors) !== JSON.stringify(prevProps.colors) ||
			foreColor !== prevProps.foreColor) {
			let newChart = this.state.chart;
			newChart.options = this.configOptions();
			this.state.chart.update();
			this.setState({ chart: newChart });
		}
	}

	configData(values, labels) {
		let { dimensions, colors } = this.props;
		let result = [];
		dimensions.forEach((dim, i) => {
			let node = {
				label: dim.name,
				backgroundColor: colors[i],
				data: values[i],
			};
			if (dim.name !== "Duration") {
				node.type = "bar";
				node.order = dim.id * 2;
			} else {
				Object.assign(node, {
					type: "line",
					fill: false,
					lineTension: 0,
					showLine: true,
					spanGaps: true,
					borderColor: colors[i],
					order: 1,
				});
			}
			result.push(node);
		});
		return {
			datasets: result,
			labels: labels,
		};
	}

	configOptions() {
		let { foreColor } = this.props;
		return {
			responsive: true,
			maintainAspectRatio: false,
			elements: {
				point: {
					radius: 0,
				},
			},
			scales: {
				x: {
					border: {
                        color: foreColor
                    },
					ticks: {
						color: foreColor,
						callback: function (_val, index) {
							// Show every 4th tick label
							return index % 4 === 0 ? this.chart.config.data.labels[index] : "";
						},
					},
                    grid: {
                        display: false
                    }
				},
				y: {
					ticks: {
						beginAtZero: true,
						display: false,
					},
                    grid: {
                        display: false
                    },
					border: {
						display: false
					}
				},
			},
			plugins: {
				tooltip: {
					mode: "index",
					callbacks: {
						label: (tooltipItem) => {
							let { dimensions, isMetric } = this.props;
							let { series } = this.state;
							let i = dimensions.findIndex(
								(dim) =>
									dim.name === tooltipItem.dataset.label
							);
							let value = FormatAmount({
								value: series[i][tooltipItem.dataIndex],
								dimension: dimensions[i],
								isMetric: isMetric,
								decimals: 0
							});
							return (
								tooltipItem.dataset.label +
								" " +
								value
							);
						},
					},
				},
				legend: {
					display: true,
					position: "bottom",
					labels: {
						color: foreColor
					}
				},
		},
		};
	}

	render() {
		return (
			<Card className="animate-content-card-up" sx={{ mb: 3, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
				<CardHeader
					title={
						<>
							<Typography component="h2" fontWeight="bold">
								Global Practice Achievements
							</Typography>
							<Typography variant="caption">
								Aggregate global performance for each week.
							</Typography>
						</>
					}
				/>
				<CardContent sx={{ pt:0 }}>
					<canvas
						width="100%"
						height="380px"
						ref={(chart) => {
							this.chart = chart;
						}}
					/>
				</CardContent>
			</Card>
		);
	}
}

export default GlobalWeeklyChart;
