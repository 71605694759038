import React from 'react';
import { Typography, Chip } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FormatAmount from './FormatAmount.tsx';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);
const arrows = [<ArrowDropDownIcon fontSize='small' />, <ArrowDropUpIcon fontSize='small' />];
const chipColors = ['error', 'success'];

class WeeklyBar extends React.Component  {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.configData = this.configData.bind(this);
        this.configOptions = this.configOptions.bind(this);
        this.returnCorrectTitle = this.returnCorrectTitle.bind(this);
    }

    componentDidMount() {
        let { weekdays, dailyTotals, dimensions, labels } = this.props;
        let amounts = [];
        let totalAmounts = [];
        for (let dimension of dimensions) {
            amounts[dimension.id] = [];
            for (let discipline = 0; discipline < labels.length; discipline++) {
                amounts[dimension.id][discipline] = new Array(7).fill(0);
            }
            totalAmounts[dimension.id] = 0;
        }
        weekdays.forEach((_unused, dayNumber) => {
            if (Object.getOwnPropertyNames(dailyTotals[dayNumber].totalsByType).length > 0) {
                let types = Object.entries(dailyTotals[dayNumber].totalsByType).map(entry => entry[0]);
                let totals = Object.entries(dailyTotals[dayNumber].totalsByType).map(entry => entry[1]);
                types.forEach((type, j) => {
                    let discipline = labels.findIndex(label => label === type);
                    totals[j].dimensionIds.forEach((dimension, i) => {
                        amounts[dimension][discipline][dayNumber] += totals[j].amounts[i];
                        totalAmounts[dimension] += totals[j].amounts[i];
                    });
                });
            }
        });
        this.setState({ weekdays: weekdays, amounts: amounts, totalAmounts: totalAmounts, labels: labels, hover: false });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selected > -1) {
            const chart = this.chartRef.current;
            chart.tooltip.setActiveElements(Array.from({length: 7}, (_, i) => ({datasetIndex: this.props.selected, index: i})));
        } else {
            const chart = this.chartRef.current;
            if (chart && chart.tooltip) {
                chart.tooltip.setActiveElements([]);
            }
        }
        if (prevProps.selected !== this.props.selected) {
            if (prevState && prevState.hover) {
                this.setState({ hover: false });
            }
        }
    }

    returnCorrectTitle(tooltipItems) {
        let { hover } = this.state;
        let result;
        switch (tooltipItems.length) {
            case 0:
                result = null;
                break;
            case 1:
                if (hover) {
                    result = tooltipItems[0].label;
                } else {
                    result = tooltipItems[0].dataset.label;
                }
                break;
            default:
                result = tooltipItems[0].dataset.label;
        }
        return result;
    }

    returnFooter(tooltipItems) {
        let { dimIndex, dimensions, isMetric } = this.props;
        let { hover } = this.state;
        if (!hover && tooltipItems.length > 0) {
            let result = 0;
            tooltipItems.forEach(tooltipItem => {
                result += tooltipItem.dataset.data[tooltipItem.dataIndex];
            });
            return 'Total: ' + FormatAmount({
                value: result,
                dimension: dimensions.find(dim => dim.id === dimIndex),
                isMetric: isMetric,
                decimals: 2
            });
        }
    }

    configData() {
        let { dimIndex, colors } = this.props;
        let { labels, amounts, weekdays } = this.state;
        let result = [];
        let dataset = amounts[dimIndex];
        labels.forEach((label, i) => {
            result.push({
                label: label,
                backgroundColor: colors[i],
                data: dataset[i]
            });
        });
        return {
            datasets: result,
            labels: weekdays
        }
    }

    configOptions() {
        let { foreColor, dimensions, isMetric, dimIndex } = this.props;
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                x: {
                    stacked: true,
                    border: {
                        color: foreColor
                    },
                    ticks: {
                        color: foreColor
                    },
                    grid: {
                        display: false
                    }
                },
                y: {
                    stacked: true,
                    border: {
                        color: foreColor
                    },
                    ticks: {
                        beginAtZero: true,
                        color: foreColor
                    },
                    grid: {
                        display: false
                    }
                }
            },
            plugins: {
                tooltip: {
                    filter: (tooltipItem) => {
                        //No tooltip on any series where value is 0
                        return (tooltipItem.dataset.data[tooltipItem.dataIndex] !== 0);
                    },
                    callbacks: {
                        label: (tooltipItem) => {
                            return (
                                (tooltipItem.chart.tooltip.getActiveElements().length === 1 ?
                                    tooltipItem.dataset.label + ' '
                                    :
                                    tooltipItem.label + ' '
                                ) +
                                FormatAmount({
                                    value: tooltipItem.dataset.data[tooltipItem.dataIndex],
                                    dimension: dimensions.find(dim => dim.id === dimIndex),
                                    isMetric: isMetric,
                                    decimals: 2
                                })
                            )
                        },
                        title: (tooltipItems) => {
                            return this.returnCorrectTitle(tooltipItems);    
                        },
                        footer: (tooltipItems) => {
                            return this.returnFooter(tooltipItems);
                        }
                    }
                },
                legend: {
					display: false
                }
            },
            onHover: () => {
                if (!this.state.hover) {
                    this.setState({ hover: true });
                    this.props.resetTooltip();
                }
            }
        }
    }

    render() {
        if (this.state === null) return null;
        let { dimensions, dimIndex, isMetric } = this.props;
        let totalThisYear = this.state.totalAmounts[dimIndex];
        let totalLastYear = this.props.lastYTD.find(amt => amt.dimensionId === dimIndex).amount;
        let value = +(totalThisYear - totalLastYear >= 0);
        return (
            <>
                <Typography component="div" sx={{ fontSize: 14, lineHeight: 1, mb: 3 }}>
                    <FormatAmount
                        value={totalThisYear}
                        dimension={dimensions.find(dim => dim.id === dimIndex)}
                        isMetric={isMetric}
                        decimals={2}
                    />
                    {' '}in total{' '}
                    <Chip
                        size='small'
                        icon={arrows[value]}
                        label={FormatAmount({
                            value: Math.abs(totalThisYear - totalLastYear),
                            dimension: dimensions.find(dim => dim.id === dimIndex),
                            isMetric: isMetric,
                            decimals: 2
                        })}
                        color={chipColors[value]}
                    />
                    {' '}vs. same period last year
                </Typography>
                <div className="weekly-chart-container">
                    <Bar
                        data={this.configData()}
                        options={this.configOptions()}
                        ref={this.chartRef}
                    />
                </div>
            </>
        )
    }
}

export default WeeklyBar;