import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ChallengeList from "../components/ChallengeList";
import PastAchievements from "../components/PastAchievements";

const ChallengesPage = ({
	athleteId,
	challenges,
	challengeList,
	challengeToggle,
	handleChallengeList,
	updateChallengeStats,
	data,
}) => {
	return (
		<Container sx={{ my: 5 }} maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<Card className="animate-content-card-up">
						<CardHeader
							title={
								<Typography component="h2" fontWeight="bold">
									Challenges
								</Typography>
							}
							action={
								<ToggleButtonGroup
									value={challengeList}
									exclusive
									onChange={handleChallengeList}
									aria-label="Filter current or future challenges"
									size="small"
									sx={{ overflow: "hidden" }}
								>
									{challengeToggle.map((option, i) => (
										<ToggleButton
											key={i}
											value={i}
											aria-label={option + " challenges"}
											sx={{
												fontWeight: "bold!important",
												borderRadius: "0!important",
											}}
										>
											{option}
										</ToggleButton>
									))}
								</ToggleButtonGroup>
							}
						/>
						<CardContent>
							<ChallengeList
								athleteId={athleteId}
								challenges={
									challengeList ? challenges.Future : challenges.Current
								}
								onSignUp={updateChallengeStats}
								dimensions={data.dimensions}
								myTeam={data.myTeam}
								myProfile={data.deloitteProfile}
								isMetric={data.athlete.metric}
							/>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card className="animate-content-card-up">
						<CardHeader
							title={
								<Typography component="h2" fontWeight="bold">
									Past Achievements
								</Typography>
							}
						/>
						<CardContent sx={{ pt: 0 }}>
							<PastAchievements challenges={data.pastAccomplishments} />
							<Typography variant="caption">
								Last year, you completed{" "}
								<strong>
									{data.athleteCombinedLastYearData.totalTrophiesLastYear}
								</strong>{" "}
								challenges.
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
};

export default ChallengesPage;
