import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
	typography: {
		fontFamily: "Open Sans, sans-serif",
		h1: {
			fontSize: '1rem',
			color: '#fff',
			'@media (min-width:600px)': {
			  fontSize: '1.25rem',
			},
		},
		overline: {
			lineHeight: 1
		},
		heroTitle: {
			fontSize: '1.25rem',
			maxWidth: '200px',
			'@media (min-width:600px)': {
			  fontSize: '1.5rem',
			  maxWidth: '340px',
			},
		},
		heroSubtitle: {
			maxWidth: '140px',
			fontSize: '0.875rem',
			'@media (min-width:600px)': {
			  maxWidth: '320px',
			  fontSize: '1rem'
			},
		}
	},
	palette: {
		type: "light",
		background: {
			default: "#f7f7f8",
			transparent: 'rgba(255,255,255,0.9)'
		},
		primary: {
			main: "#86bc25",
			tabs: "#efeff1",
		},
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					background: "#18181b",
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					paddingTop: '0'
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					border: "1px solid #e8e8e8",
					boxShadow: "none",
				},
			},
		},
		MuiTable: {
			styleOverrides: {
				root: {
					background: "#fff",
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					background: "#fff",
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				containedSecondary: {
					backgroundColor: '#2f2f36',
					color: '#fff',
					textTransform: 'none',
					'&:hover': {
						backgroundColor: '#3f3f46',
					},
				},
			},
		},
        MuiDialog: {
            styleOverrides: {
                paper: {
                    margin: '0',
                    '@media (max-width:600px)': {
                        margin: 0,
						width: '100%',
						height: '100%',
						maxHeight: '100%',
						borderRadius: 0
                    },
                },
            },
        }
	},
});
